import { watcher } from "store";
import { isAnyOf } from "@reduxjs/toolkit";

import { accountServerThunks } from "store/account";
import { currenciesThunks } from "store/currencies";

import { createScenario } from "./redux";

export const accountLoadScenario = createScenario<Account>(
  ({ getState, resolve }) => {
    const { isLoaded, data } = getState().account;
    if (isLoaded) {
      return resolve(data);
    }

    watcher.startListening({
      effect: ({ payload }, { unsubscribe }) => {
        unsubscribe();
        resolve(payload);
      },
      matcher: isAnyOf(
        accountServerThunks.loadAccountServer.fulfilled,
        accountServerThunks.loadAccountServer.rejected,
      ),
    });
  },
);

export const currenciesLoadScenario = createScenario<CurrencyWithLimits[]>(
  ({ getState, resolve }) => {
    const { isLoaded, entities } = getState().currencies;
    if (isLoaded) {
      return resolve(Object.values(entities));
    }

    watcher.startListening({
      effect: ({ payload }, { unsubscribe }) => {
        unsubscribe();
        resolve(payload);
      },
      matcher: isAnyOf(
        currenciesThunks.loadCurrencies.fulfilled,
        currenciesThunks.loadCurrencies.rejected,
      ),
    });
  },
);

export const accountPaymentMethodsScenario = createScenario<{
  [id: number]: PaymentMethod;
}>(({ resolve, getState }) => {
  const state = getState().account;
  if (state.isLoadedPaymentMethods) {
    resolve(state.paymentMethods.entities);
  }

  watcher.startListening({
    effect: (_, { getState, unsubscribe }) => {
      unsubscribe();
      resolve(getState().account.paymentMethods.entities);
    },
    matcher: isAnyOf(
      accountServerThunks.loadPaymentMethodsServer.fulfilled,
      accountServerThunks.loadPaymentMethodsServer.rejected,
    ),
  });
});
